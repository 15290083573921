import './App.css';
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import Home from "./app/Home";
import Login from "./app/Login";
import Surveys from "./app/componants/surveys/Surveys";
import SurveyCreator from "./app/componants/surveys/SurveyCreator";
import ViewSurveys from "./app/componants/surveys/ViewSurveys";
import SurveyTaker from "./app/componants/surveys/SurveyTaker";
import SurveyParticipants from "./app/componants/surveys/SurveyParticipants";
import SurveyTakerAdmin from "./app/componants/surveys/SurveyTakerAdmin";
import Directory from "./app/componants/directory/Directory";
import UserAdmin from "./app/componants/useradmin/UserAdmin";
import UserForm from "./app/componants/useradmin/UserForm";
import {useAuthState} from "react-firebase-hooks/auth";
import {analytics, auth, useSetupNotifications} from "./app/firebase/firebase";
import {StompSessionProvider} from "react-stomp-hooks";
import {websocketUrl} from "./app/defaults/AppConfig";
import {useEffect, useState} from "react";
import Documents from "./app/componants/documents/Documents";
import RepositoryAdmin from "./app/componants/documents/RepositoryAdmin";
import RepositoryForm from "./app/componants/documents/RepositoryForm";
import MessageForm from "./app/componants/messaging/MessageForm";
import ManageMessages from "./app/componants/messaging/ManageMessages";
import WorkOrders from "./app/componants/it/WorkOrders";
import WorkOrderForm from "./app/componants/it/WorkOrderForm";
import SurveyLinkTaker from "./app/componants/surveys/SurveyLinkTaker";
import Unsubscribe from "./app/componants/subscriptions/Unsubscribe";
import Links from "./app/componants/links/Links";
import GiftCards from "./app/componants/giftcards/GiftCards";
import EmergencyInformation from "./app/componants/emergency/EmergencyInformation";
import PMManagement from "./app/componants/it/PMManagement";
import PMForm from "./app/componants/it/PMForm";
import Benefits from "./app/componants/benefits/Benefits";
import ViewBenefit from "./app/componants/benefits/ViewBenefit";
import BenefitsForm from "./app/componants/benefits/BenefitsForm";
import Calendar from "./app/componants/calendar/Calendar";
import CalendarAdmin from "./app/componants/calendar/CalendarAdmin";
import CalendarAdminForm from "./app/componants/calendar/CalendarAdminForm";
import Vehicles from "./app/componants/vehicles/Vehicles";
import ResidentVehicleForm from "./app/componants/vehicles/ResidentVehicleForm";
import EmployeeVehicleForm from "./app/componants/vehicles/EmployeeVehicleForm";
import DocumentsRepo from "./app/componants/documents/DocumentsRepo";
import ChatMessaging from "./app/componants/chat/ChatMessaging";
import Chatting from "./app/componants/chat/Chatting";
import {logEvent } from "firebase/analytics";
import Celebrations from "./app/componants/Celebrations";
import FacilityServices from "./app/componants/facilityservices/FacilityServices";
import FacilityServicesForm from "./app/componants/facilityservices/FacilityServicesForm";
import Media from "./app/componants/media/Media";
import AppAdmin from "./app/componants/admin/AppAdmin";
import AppLocationsAdmin from "./app/componants/admin/AppLocationsAdmin";
import MenuItemsAdmin from "./app/componants/admin/MenuItemsAdmin";
import UserRequests from "./app/componants/it/UserRequests";
import About from "./app/componants/About";

function PateViewLogger() {
    let location = useLocation();
    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_location: window.location.href,
            page_path: location.pathname + location.search
        })
    }, [location]);  // Re-run this effect every time the location changes
}

function App() {
    useSetupNotifications()
    const [user, loading] = useAuthState(auth);
    const [authHeader, setAuthHeader] = useState()

    useEffect(() => {
        if(user){
            user.getIdToken().then(token => setAuthHeader(token))
        }
    }, [user]);

    const resetAuthHeader = () =>{
        user.getIdToken().then(token => setAuthHeader(token));
    }

    if(loading){
        return null;
    }

    if(user && !authHeader){
        return null;
    }


    return (
        <div className="App">
            <BrowserRouter>
                <PateViewLogger />
                {user && authHeader ? (
                    <StompSessionProvider heartbeatIncoming={45000} heartbeatOutgoing={45000} url={websocketUrl} connectHeaders={{Authorization: `jwt ${authHeader}`}} onStompError={(frame) => {
                        console.log(frame.body)
                        resetAuthHeader()
                    }}>
                        <Routes>
                            <Route exact path="/" element={<Home/>} />
                            <Route exact path={"/surveys"} element={<Surveys/>} />
                            <Route exact path={"/surveys/edit/:id"} element={<SurveyCreator/>} />
                            <Route exact path={"/surveys/create"} element={<SurveyCreator/>} />
                            <Route exact path={"/surveys/view"} element={<ViewSurveys/>} />
                            <Route exact path={"/surveys/take-survey"} element={<SurveyTaker/>} />
                            <Route exact path={"/surveys/take-survey/:id"} element={<SurveyTakerAdmin/>} />
                            <Route exact path={"/surveys/participants/:id"} element={<SurveyParticipants/>} />
                            <Route exact path={"/directory"} element={<Directory />} />
                            <Route exact path="/login" element={<Login/>} />
                            <Route exact path={"/user-admin"} element={<UserAdmin />} />
                            <Route exact path={"/user-admin/:id"} element={<UserForm />} />
                            <Route exact path={"/documents"} element={<Documents />} />
                            <Route exact path={"/documents/:id"} element={<DocumentsRepo />} />
                            <Route exact path={"/documents/:id/*"} element={<DocumentsRepo />} />
                            <Route exact path={"/repository-admin"} element={<RepositoryAdmin />} />
                            <Route exact path={"/repository-admin/form"} element={<RepositoryForm />} />
                            <Route exact path={'/send-message'} element={<ManageMessages />} />
                            <Route exact path={'/send-message/form'} element={<MessageForm />} />
                            <Route exact path={'/send-message/form/:id'} element={<MessageForm />} />
                            <Route exact path={'/workorders'} element={<WorkOrders closed={false} />} />
                            <Route exact path={'/workorders/closed'} element={<WorkOrders closed={true} />} />
                            <Route exact path={'/workorders/form'} element={<WorkOrderForm />} />
                            <Route exact path={'/workorders/edit/:id'} element={<WorkOrderForm />} />
                            <Route exact path={'/it-requests'} element={<UserRequests />} />
                            <Route exact path={'/it/pm'} element={<PMManagement />} />
                            <Route exact path={'/it/pm/form'} element={<PMForm />} />
                            <Route exact path={'/it/pm/form/:id'} element={<PMForm />} />
                            <Route exact path={'/survey-link/:id'} element={<SurveyLinkTaker />} />
                            <Route exact path={'/unsubscribe/:id'} element={<Unsubscribe />} />
                            <Route exact path={'/web-links/:id/:title/*'} element={<Links />} />
                            <Route exact path={'/gift-cards'} element={<GiftCards />} />
                            <Route exact path={'/emergency-information'} element={<EmergencyInformation />} />
                            <Route exact path={'/benefits'} element={<Benefits />} />
                            <Route exact path={'/benefits/*'} element={<Benefits />} />
                            <Route exact path={'/view-benefit/:id'} element={<ViewBenefit />} />
                            <Route exact path={'/benefits/edit/:id'} element={<BenefitsForm />}/>
                            <Route exact path={'/benefits/new'} element={<BenefitsForm />} />
                            <Route exact path={'/calendar'} element={<Calendar />} />
                            <Route exact path={'/calendar-admin'} element={<CalendarAdmin />} />
                            <Route exact path={'/calendar-admin/new'} element={<CalendarAdminForm />} />
                            <Route exact path={'/calendar-admin/edit/:id'} element={<CalendarAdminForm />} />
                            <Route exact path={'/vehicles'} element={<Vehicles />} />
                            <Route exact path={'/vehicles/resident/:id'} element={<ResidentVehicleForm />} />
                            <Route exact path={'/vehicles/team-member/:id'} element={<EmployeeVehicleForm />} />
                            <Route exact path={'/chat-messaging'} element={<ChatMessaging />} />
                            <Route exact path={'/chat-messaging/chat'} element={<Chatting />} />
                            <Route exact path={'/celebrations'} element={<Celebrations />} />
                            <Route exact path={'/facility-services'} element={<FacilityServices />} />
                            <Route exact path={'/facility-services/form'} element={<FacilityServicesForm />} />
                            <Route exact path={'/facility-services/form/:id'} element={<FacilityServicesForm />} />
                            <Route exact path={'/media/:id/*'} element={<Media />} />
                            <Route exact path={'/calendar/public/:calendar'} element={<Calendar />} />
                            <Route exact path={'/app-admin'} element={<AppAdmin />} />
                            <Route exact path={'/app-admin/app-locations'} element={<AppLocationsAdmin />} />
                            <Route exact path={'/app-admin/app-locations/:location'} element={<AppLocationsAdmin />} />
                            <Route exact path={'/app-admin/menu-items'} element={<MenuItemsAdmin />} />
                            <Route exact path={'/app-admin/menu-items/:item'} element={<MenuItemsAdmin />} />
                            <Route exact path={'/about'} element={<About />} />
                        </Routes></StompSessionProvider>) : (
                    <Routes>
                        <Route exact path={'/unsubscribe/:id'} element={<Unsubscribe />} />
                        <Route exact path={'/survey-link/:id'} element={<SurveyLinkTaker />} />
                        <Route exact path={'/calendar/public/:calendar'} element={<Calendar />} />
                        <Route path={"*"} element={<Login/>} />
                        <Route exact path={'/about'} element={<About />} />
                    </Routes>
                )}
            </BrowserRouter>
        </div>
    );
}

export default App;
