import React, {useEffect, useState} from "react";
import Select from "react-select";
import MyCheckBox from "../MyCheckBox";
import {doGet, doPostBody, downloadFileFromUrlGet, imageUploadHandler} from "../../services/rest";
import {Editor} from "@tinymce/tinymce-react";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import FileSaver from 'file-saver';
function NewEventForm({myAdminCalendars, currentEvent, reset}) {
    const [event, setEvent] = useState({});
    const [locationSelections, setLocationSelections] = useState([]);
    const [selectedCalendars, setSelectedCalendars] = useState([]);
    const [rrule, setRrule] = useState({});
    const [byWeekday, setByWeekDay] = useState([])
    const [displayOnly, setDisplayOnly] = useState(false)
    const options = [
        {
            label: "Mon",
            value: "mo"
        }, {
            label: "Tue",
            value: "tu"
        }, {
            label: "Wed",
            value: "we"
        }, {
            label: "Thu",
            value: "th"
        }, {
            label: "Fri",
            value: "fr"
        }, {
            label: "Sat",
            value: "sa"
        },{
            label: "Sun",
            value: "su"
        }
    ];


    useEffect(() =>{
        const itemList = [];
        for(const admin of myAdminCalendars){
            itemList.push({value: admin.id, label: admin.calendarName});
        }
        setLocationSelections([
            {
                label: 'Calendars',
                options: itemList,
            }
        ]);
        }, [myAdminCalendars])

    useEffect(() => {
        if(currentEvent){
            setDisplayOnly(true)
            if (myAdminCalendars && currentEvent.calendar.every(calendar =>
                !myAdminCalendars?.some(adminCalendar => adminCalendar.id === calendar.id) ?? false)){
                setDisplayOnly(true)
            }


            if(currentEvent.allDay){
                currentEvent.start = currentEvent.start.split("T")[0]
            }
            if(currentEvent.rrule){
                currentEvent.recurrance = true
                setRrule(currentEvent.rrule);
                setByWeekDay(currentEvent.rrule.byweekday);
            }
            const calendarsSelections = []
            for(const cal of currentEvent.calendar){
                calendarsSelections.push({value: cal.id, label: cal.calendarName})
            }
            setSelectedCalendars(calendarsSelections)
            setEvent(currentEvent);
        }else{
            setEvent(prevState => ({
                ...prevState,
                allDay: false
            }))
        }
    }, [currentEvent]);

    const onChange = event => {
        setEvent(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const toggleAllDay = () =>{
        setEvent(prevState => ({
            ...prevState,
            allDay: !prevState.allDay
        }))
    }

    const toggleDisplay = () =>{
        setEvent(prevState => ({
            ...prevState,
            display: prevState.display === 'block' ? undefined : 'block'
        }))
    }

    const toggleRecurrance = () =>{
        setEvent(prevState => ({
            ...prevState,
            recurrance: !prevState.recurrance
        }))
    }

    const updateDescription = description => {
        setEvent(prevState => ({
            ...prevState,
            description: description
        }))
    }

    const handleChanteRrule = event =>{
        setRrule(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }
    const dayCheckBoxChange = event => {
        if(!byWeekday.includes(event)){
            setByWeekDay(byWeekday => [...byWeekday, event])
        }else{
            setByWeekDay(byWeekday.filter(item => item !== event))
        }
    }

    const deleteEvent = async () => {
        if(window.confirm("Are you sure you want to delete this event?")){
            const resp = await doGet(`calendar/delete/${event.id}`)
            window.alert(resp.resp)
            reset()
        }
    }

    const saveEvent = async () => {
        if(selectedCalendars.length === 0){
            window.alert("Please select at least one calendar to assign the even to.")
            return
        }
        if(!event.title){
            window.alert("please enter a even title to continue.")
            return
        }
        if(event.recurrance){
            if(!rrule.interval){
                rrule.interval = 1
            }
            if(!rrule.dtstart){
                rrule.dtstart = event.start
            }
            if(!event.allDay && !event.end){
                window.alert("Please enter an end time")
                return
            }
            if(isNaN(rrule.interval) || rrule.interval <= 1){
                if((event.allDay === undefined || !event.allDay) && event.end === undefined){
                    window.alert("Interval must be greater then 1")
                    return
                }
            }
            if(rrule.freq === 'weekly' && byWeekday.length < 1){
                window.alert("Please select at least one day of the week to continue")
                return
            }
            rrule.byweekday = byWeekday
            if(event.allDay){
                let myDate = new Date(event.start)
                rrule.dtstart = myDate
                myDate.setUTCHours(0,0,0,0)
                event.start = myDate.toISOString()
                myDate.setUTCHours(23,59,59)
                event.end = myDate.toISOString()
            }
            event.rrule = rrule
        }else{
            if((!event.allDay) && event.start === undefined){
                window.alert("Please select the start date to continue")
                return
            }

            if((!event.allDay) && event.end === undefined){
                window.alert("Please enter a end date to continue")
                return
            }

            if(event.allDay && event.start === undefined){
                window.alert("Please enter a start date to continue.")
                return
            }
            if(event.allDay){
                let myDate = new Date(event.start)
                myDate.setUTCHours(0,0,0,0)
                event.start = myDate.toISOString()
                myDate.setUTCHours(23,59,59)
                event.end = myDate.toISOString()
            }
        }

        const calendarsToAdd = []
        for(const calendarCheck of selectedCalendars){
            const calendarToAdd = myAdminCalendars.find(calendar => calendar.id === calendarCheck.value);
            if (calendarToAdd) {
                calendarsToAdd.push(calendarToAdd)
            }
        }
        event.calendar = calendarsToAdd
        let save = await  doPostBody('calendar/save-calendar-event', event);
        if(save.toString().trim() === 'true'){
            reset()
        }else{
            window.alert("Something has gone wrong, your event has not be saved.")
        }

    }

    const formatDate = (date) => {
        return moment(date).format("hh:mm a")
    }

    const createIcal = async (event, currentEvent) =>{
        const blob = await downloadFileFromUrlGet(`calendar/ical/${currentEvent.id}`)
        FileSaver.saveAs(new Blob([blob]), 'event.ics')
    }

    if(displayOnly){
        let calanderString = "Calendar(s): "
        for(const i of currentEvent.calendar){
            calanderString += i.calendarName+", "
        }
        calanderString = calanderString.substring(0, calanderString.length -2)
        return(<div className={'inner-container'}>
            <>
                {myAdminCalendars && currentEvent.calendar.every(calendar =>
                    myAdminCalendars.some(adminCalendar => adminCalendar.id === calendar.id) ?? false) && <div style={{display: 'flex'}}><FontAwesomeIcon cursor={'pointer'} onClick={() => setDisplayOnly(false)} icon={faEdit} /></div>}
                <div>
                    <h5>{calanderString}</h5>
                </div>
                <div>{currentEvent.title}</div>
                <br/>
                {currentEvent.allDay ? '' :
                    currentEvent.rrule != null ? <>
                            <div><span
                                style={{fontWeight: 'bold'}}>Start Time:</span> {formatDate(currentEvent.rrule.dtstart)}  &nbsp;
                                <span style={{fontWeight: 'bold'}}>End Time: </span>{formatDate(currentEvent.end)}
                            </div>
                            <br/></> :
                        <>
                            <div><span style={{fontWeight: 'bold'}}>Start Time:</span> {formatDate(currentEvent.start)}&nbsp;<span style={{fontWeight: 'bold'}}> End Time: </span>{formatDate(currentEvent.end)}
                            </div><br/></>}
                {currentEvent.eventLocation !== null ? <><div>Location: {currentEvent.eventLocation}</div> <br/></>: ''}
                <div style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html: currentEvent.description}}></div>
                <div><a href={'#'} onClick={e => createIcal(e, currentEvent)}>Add To Personal Calendar</a> </div>
            </>
        </div>)
    }

    return(<div className={'inner-container'}>
        {event.id && <div style={{display: 'flex', alignItems: 'flex-start', paddingBottom: 10}}>
            <FontAwesomeIcon onClick={deleteEvent} cursor={'pointer'} icon={faTrash} />
        </div>}
        <Select
            placeholder={'Select Calendar'}
            closeMenuOnSelect={false}
            isMulti
            onChange={setSelectedCalendars}
            options={locationSelections}
            value={selectedCalendars}
        />
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
            <label style={{width: 150, textAlign: "left"}}>Event Title:</label>
            <input type={'text'} className={'search-input'} name={'title'} value={event.title} onChange={onChange}
                   placeholder={'Event Title'} style={{marginTop: 10}}/>
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
            <label style={{width: 150, textAlign: "left"}}>Event Location:</label>
            <input type={'text'} className={'search-input'} name={'eventLocation'} value={event.eventLocation}
                   onChange={onChange}
                   placeholder={'Event Location'} style={{marginTop: 10}}/>
        </div>
        <MyCheckBox label={"This is an all day event"} id={'allDay'} onChange={toggleAllDay} checked={event.allDay}/>
        <MyCheckBox label={"Display Event As Block"} id={'display'} onChange={toggleDisplay} checked={event?.display === 'block'}/>
        <MyCheckBox label={"This is a recurring event"} id={'recurrance'} onChange={toggleRecurrance}
                    checked={event.recurrance}/>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
            <label style={{width: 150, textAlign: "left"}}>{event.allDay ? 'Event Date: ' : 'Event Start:'}</label>
            <input type={event.allDay ? 'date': 'datetime-local'} name={'start'} value={event.start} onChange={onChange}
                   className={'search-input'}/>
        </div>
        {! event.allDay && <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', paddingTop: 10, paddingBottom: 10}}>
            <label style={{width: 150, textAlign: "left"}}>Event End:</label>
            <input type={'datetime-local'} name={'end'} value={event.end} onChange={onChange}
                   className={'search-input'}/>
        </div>}
        {event.recurrance && <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                <label style={{width: 150, textAlign: "left"}}>Select Recurrence Type: </label>
                <select onChange={handleChanteRrule} name={'freq'} value={rrule.freq} className={'search-input'}>
                    <option></option>
                    <option value={'daily'}>Daily</option>
                    <option value={'weekly'}>Weekly</option>
                    <option value={'monthly'}>Monthly</option>
                    <option value={'yearly'}>Yearly</option>
                </select>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                <label style={{width: 150, textAlign: "left"}}>Interval:</label>
                <input type={'number'} name={'interval'} value={rrule.interval ?? 1}
                       onChange={handleChanteRrule}
                       className={'search-input'}/>
            </div>
            {rrule.freq === 'weekly' && <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', paddingTop: 10}}>
                <label style={{width: 150, textAlign: "left"}}>Days of the week:</label>
                <div style={{flex: 1, flexDirection: 'row', display: "flex"}}>{options.map(option => (
                    <MyCheckBox id={option.value} label={option.label} onChange={() => dayCheckBoxChange(option.value)} checked={byWeekday.includes(option.value)} />))}</div>
            </div>}
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', paddingTop: 10}}>
                <label style={{width: 150, textAlign: "left"}}>End After</label>
                <input type={'number'} name={'count'} value={rrule.count}
                       onChange={handleChanteRrule}
                       className={'search-input'}/>
                <label style={{width: 150, textAlign: "left"}}>Occurrences</label>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', paddingTop: 10, paddingBottom: 10}}>
                <label style={{width: 150, textAlign: "left"}}>Or End On</label>
                <input type={'date'} name={'until'} value={rrule.untill}
                       onChange={handleChanteRrule}
                       className={'search-input'}/>
            </div>
        </>}
        <Editor
            onEditorChange={(e) => updateDescription(e)}
            licenseKey={'gpl'}
            value={event.description}
            init={{
                height: 500,
                menubar: false,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'table'
                ],
                toolbar: 'undo redo | blocks | link table|' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | image | bullist numlist outdent indent | ' +
                    'removeformat | toggle',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                automatic_uploads: true,
                images_upload_handler: imageUploadHandler,
            }}
            tinymceScriptSrc={'/tinymce/tinymce.min.js'}
        />
        <button onClick={saveEvent} style={{margin: 10}} className={'submitPost'}>Save</button>
    </div>)
}

export default NewEventForm
